import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class PlaceNotification extends Parse.Object {

  constructor() {
    super('PlaceNotification');
  }

  static getInstance() {
    return this;
  }

  create(data: any = {}): Promise<PlaceNotification> {
    return new Promise((resolve, reject) => {
      const placeNotification = new PlaceNotification();
      placeNotification.save(data).then((data: PlaceNotification) => resolve(data), error => reject(error));
    });
  }

  load(params: any = {}): Promise<PlaceNotification[]> {

    return new Promise(async (resolve, reject) => {

      let query = new Parse.Query(PlaceNotification);

      if (params.place) {
        query.equalTo('place', params.place);
      }

      if (params.containedIn) {
        query.containedIn('place', params.containedIn);
      }

      if (params.scheduled) {
        query.containedIn('status', ['Scheduled', 'Sent']);
      } else {
        query.equalTo('status', 'Sent');
      }

      if (params.global) {
        query.containedIn('isGlobal', [true, false]);
      } else {
        query.containedIn('isGlobal', [false]);
      }
      
      query.descending('createdAt');
      query.include(['place']);
      query.doesNotExist('deletedAt');

      const limit = params.limit || 100;
      const page = params.page || 0;
      query.skip(page * limit);
      query.limit(limit);

      query.find().then((data: PlaceNotification[]) => resolve(data), error => reject(error));
    });
  }

  markAsRead(placeNotificationId: string): Promise<boolean> {
    return Parse.Cloud.run('readPlaceNotification', { placeNotificationId: placeNotificationId });
  }

  isReaded(placeNotification: PlaceNotification): Promise<boolean> {
    return Parse.Cloud.run('isPlaceNotificationReaded', { placeNotificationId: placeNotification.id });
  }

  get text(): string {
    return this.get('text');
  }

  get place(): any {
    return this.get('place');
  }

  get status(): any {
    return this.get('status');
  }

  get scheduledAt(): any {
    return this.get('scheduledAt');
  }

  get isGlobal(): any {
    return this.get('isGlobal');
  }

  get color(): any {
    return this.get('color');
  }

  get link(): any {
    return this.get('link');
  }

  get recipients(): any {
    return this.get('recipients');
  }

  get delivered(): any {
    return this.get('delivered');
  }

  get opened(): any {
    return this.get('opened');
  }
}

Parse.Object.registerSubclass('PlaceNotification', PlaceNotification);
