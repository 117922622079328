import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class UserNotification extends Parse.Object {

  constructor() {
    super('UserNotification');
  }

  static getInstance() {
    return this;
  }

  create(data: any = {}): Promise<UserNotification> {
    return new Promise((resolve, reject) => {
      const userNotification = new UserNotification();
      userNotification.save(data).then((data: UserNotification) => resolve(data), error => reject(error));
    });
  }

  load(params: any = {}): Promise<UserNotification[]> {

    return new Promise((resolve, reject) => {

      let query = new Parse.Query(UserNotification);

      if (params.fromUser) {
        query.equalTo('fromUser', params.fromUser);
      }

      if (params.toUser) {
        query.equalTo('toUser', params.toUser);
      }
      
      query.equalTo('status', 'Sent');
      query.descending('createdAt');
      query.include(['fromUser', 'toUser']);
      query.doesNotExist('deletedAt');

      const limit = params.limit || 100;
      const page = params.page || 0;
      query.skip(page * limit);
      query.limit(limit);

      query.find().then((data: UserNotification[]) => resolve(data), error => reject(error));
    });
  }

  loadOne(id: string): Promise<UserNotification> {
    const query = new Parse.Query(UserNotification);
    query.include(['fromUser', 'toUser']);
    return query.get(id);
  }

  get text(): string {
    return this.get('text');
  }

  get fromUser(): any {
    return this.get('fromUser');
  }

  get toUser(): any {
    return this.get('toUser');
  }
}

Parse.Object.registerSubclass('UserNotification', UserNotification);
