import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared.module';
import { PrivacyPolicyPage } from './privacy-policy.page';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PrivacyPolicyPage
  ]
})
export class PrivacyPolicyPageModule {}
